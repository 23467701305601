<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="发货信息"
    :visible.sync="show"
    width="400px"
  >
    <div v-loading="loading" class="content">
      <el-form size="small" label-width="100px">
        <el-form-item label="订单编号：">
          <span>{{ logInfoForm.purchaseNo }}</span>
        </el-form-item>

        <el-form-item label="配送方式：">
          <span>{{
            +logInfoForm.type === 3
              ? '快递配送'
              : +logInfoForm.type === 2
              ? '零单物流'
              : +logInfoForm.type === 1
              ? '整车物流'
              : '-'
          }}</span>
        </el-form-item>

        <template v-if="+logInfoForm.type === 1">
          <el-form-item label="车牌号：">
            <span>{{ logInfoForm.carNo }}</span>
          </el-form-item>

          <el-form-item label="联系电话：">
            <span>{{ logInfoForm.phone }}</span>
          </el-form-item>
        </template>

        <template v-if="+logInfoForm.type === 2">
          <el-form-item label="快递公司：">
            <span>{{ logInfoForm.logisticsCompany }}</span>
          </el-form-item>
          <el-form-item label="联系电话：">
            <span>{{ logInfoForm.phone }}</span>
          </el-form-item>
        </template>

        <template v-if="logInfoForm.type === 3">
          <el-form-item label="快递公司：">
            <span>{{ logInfoForm.logisticsCompany }}</span>
          </el-form-item>

          <el-form-item label="快递编号：">
            <span>{{ logInfoForm.logisticsNo }}</span>
          </el-form-item>
        </template>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">取消</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  props: ['currentNo'],
  mixins: [dialogCommonParams],
  data() {
    return {
      logInfoForm: {
        purchaseNo: '',
        type: '',
        logisticsCompany: '',
        logisticsNo: '',
        carNo: '',
        phone: ''
      },
      loading: false
    }
  },
  created() {
    this.logInfo()
  },
  methods: {
    async logInfo() {
      try {
        this.loading = true
        const r = await this.$api.supplypurchase.logInfo({
          purchaseNo: this.currentNo
        })
        if (r.purchaseLogistics) {
          this.logInfoForm = r.purchaseLogistics
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
