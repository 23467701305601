<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="采购金额详情"
    :visible.sync="show"
    width="600px"
  >
    <el-table
      :data="payTypeEntityList"
      max-height="350"
      border
      size="small"
      style="width: 100%"
    >
      <el-table-column show-overflow-tooltip prop="purchaseNo" label="付款方式">
        <template slot-scope="{ row }">
          {{ payTypeObj[+row.payType] || '-' }}
        </template>
      </el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="orderTime"
        label="付款金额(元)"
      >
        <template slot-scope="{ row }">
          {{ row.payPrice || 0 }}
        </template>
      </el-table-column>
      <el-table-column show-overflow-tooltip label="付款时间">
        <template slot-scope="{ row }">
          <span>{{ row.payTime | date }}</span>
        </template>
      </el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['payTypeEntityList'],
  data() {
    return {
      loading: false,
      payTypeObj: {
        0: '扫码支付',
        1: '工行卡支付',
        2: '线下支付',
        3: '余额/赊销'
      }
    }
  },
  created() {
    if (!this.isCreated) {
      this.getOrderDetail({
        purchaseId: this.currentItem.id,
        limit: 1000,
        page: 1
      })
    }
  },
  methods: {
    async getOrderDetail(params) {
      try {
        this.loading = true
        const res = await this.$api.orderList.getOrderDetail(params)
        this.tableData = res.detailList
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
