<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="采购详情"
    :visible.sync="show"
    width="1100px"
  >
    <div>
      <el-table
        v-loading="loading"
        :data="purchaseDetailData"
        max-height="280"
        style="width: 100%"
      >
        <el-table-column
          show-overflow-tooltip
          prop="goodsName"
          label="商品名称"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="barNo"
          label="商品条形码"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="brandName"
          label="品牌"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="purchaseUnit"
          label="采购单位"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="inPrice"
          label="采购单价(元)"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="采购数量"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payPrice"
          label="金额小计(元)"
        ></el-table-column>
      </el-table>
      <pagination
        slot="pagination"
        :totalCount="totalCount"
        :page-size.sync="params.limit"
        :page.sync="params.page"
        @change="getData"
      />
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['currentNo'],
  data() {
    return {
      loading: false,
      totalCount: 0,
      params: {
        limit: 20,
        page: 1
      },
      purchaseDetailData: []
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true

        const sendData = { ...this.params }

        sendData.purchaseNo = this.currentNo

        const res = await this.$api.supplypurchase.mainSupplierList(sendData)

        this.purchaseDetailData = ((res.page && res.page.list) || []).map(
          item => {
            return {
              goodsName: item.goods && item.goods.goodsName,
              barNo: item.goods && item.goods.barNo,
              brandName: item.goods && item.goods.brandName,
              purchaseUnit: item.goods && item.goods.purchaseUnit,
              goodsNum: item.goodsNum,
              inPrice: item.inPrice,
              payPrice: item.payPrice
            }
          }
        )
        this.totalCount = res.page.totalCount
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  margin: 15px 0;
}
.file {
  opacity: 0;
  left: -1000px;
  position: absolute;
}
.tip {
  font-size: 14px;
  letter-spacing: 0px;
  color: #666666;
  margin-bottom: 15px;
}
.file-name {
  margin-left: 12px;
  font-size: 14px;
}
</style>
