<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :title="verificationStatus == 4 ? '收货详情' : '确认收货'"
    :visible.sync="show"
    width="1200px"
  >
    <el-table
      v-loading="loading"
      :data="productDetailData"
      max-height="300"
      style="width: 100%"
    >
      <el-table-column
        show-overflow-tooltip
        prop="goodsName"
        label="商品名称"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="barNo"
        label="商品条形码"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="purchaseUnit"
        label="采购单位"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="inPrice"
        label="采购单价(元)"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="goodsNum"
        label="采购数量"
      ></el-table-column>
      <el-table-column
        show-overflow-tooltip
        prop="payPrice"
        label="金额小计(元)"
      ></el-table-column>
      <el-table-column
        width="120"
        prop="supplyDeliverNum"
        label="供应商发货数量"
      ></el-table-column>
      <el-table-column
        :width="verificationStatus == 3 ? 155 : 120"
        label="收货数量"
      >
        <template slot-scope="{ row }">
          <el-input-number
            @blur="
              () => {
                !row.middlemanConfirmNum ? (row.middlemanConfirmNum = 1) : ''
              }
            "
            v-if="verificationStatus == 3"
            :min="1"
            :max="row.supplyDeliverNum"
            size="mini"
            v-model="row.middlemanConfirmNum"
          >
          </el-input-number>
          <span v-else>{{ row.middlemanConfirmNum }}</span>
        </template>
      </el-table-column>
    </el-table>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
      <el-button
        v-if="verificationStatus == 3"
        :disabled="!productDetailData.length"
        :loading="btnLoading"
        type="primary"
        @click="inStorageHandler"
      >
        确认收货
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'
export default {
  mixins: [dialogCommonParams],
  props: ['verificationStatus', 'currentNo'],
  data() {
    return {
      loading: false,
      btnLoading: false,
      productDetailData: []
    }
  },
  created() {
    this.mainSupplierList({ purchaseNo: this.currentNo, limit: 1000, page: 1 })
  },
  methods: {
    async mainSupplierList(params) {
      try {
        this.loading = true
        const res = await this.$api.supplypurchase.mainSupplierList(params)

        this.productDetailData = ((res.page && res.page.list) || []).map(
          item => {
            return {
              goodsId: item.goodsId,
              goodsName: item.goods && item.goods.goodsName,
              barNo: item.goods && item.goods.barNo,
              brandName: item.goods && item.goods.brandName,
              purchaseUnit: item.goods && item.goods.purchaseUnit,
              goodsNum: item.goodsNum,
              conversionRatio: item.goods && item.goods.conversionRatio,
              inPrice: item.inPrice,
              payPrice: item.payPrice,
              supplyDeliverNum: item.receivingGoods.supplyDeliverNum,
              middlemanConfirmNum:
                item.receivingGoods.middlemanConfirmNum ||
                item.receivingGoods.supplyDeliverNum ||
                1
            }
          }
        )
        this.totalCount = res.page.totalCount
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    inStorageHandler() {
      this.$confirm(
        '请再次确认采购商品均已收到，一旦操作将不可进行逆转！',
        '提示',
        {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }
      ).then(async () => {
        try {
          this.btnLoading = true

          await this.$api.supplypurchase.mainSupplierSave({
            purchaseNo: this.currentNo,
            goodsNumReqList: this.productDetailData
          })
          this.$message.success('确定收货成功！')
          this.show = false
          this.$emit('getData')
        } catch (e) {
          console.log(e)
        } finally {
          this.btnLoading = false
        }
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
