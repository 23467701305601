<template>
  <page>
    <template slot="headerLeft">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="采购单号" prop="purchaseNo">
          <el-input
            v-model="params.purchaseNo"
            clearable
            placeholder="采购单号"
          />
        </el-form-item>
        <el-form-item label="制单人" prop="operator">
          <el-input v-model="params.operator" clearable placeholder="制单人" />
        </el-form-item>
        <el-form-item label="采购日期" prop="date">
          <el-date-picker
            v-model="params.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>

        <el-form-item label="供应商" prop="supplierId">
          <el-select v-model="params.supplierId" clearable>
            <el-option
              v-for="item in supplierList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="采购状态" prop="status">
          <el-select v-model="params.status" clearable>
            <el-option
              v-for="item in orderStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="付款状态" prop="payStatus">
          <el-select v-model="params.payStatus" clearable>
            <el-option
              v-for="item in payStatusList"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </template>

    <div class="table-wrap">
      <el-table :data="dataList" v-loading="loading" height="100%">
        <el-table-column
          show-overflow-tooltip
          prop="purchaseNo"
          label="采购单号"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="orderTime"
          label="采购日期"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="operatorName"
          label="制单人"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="supplierName"
          label="供应商"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="goodsNum"
          label="总数量"
        ></el-table-column>
        <el-table-column show-overflow-tooltip prop="payPrice" label="采购金额">
          <template slot-scope="{ row }">
            <span>{{ row.payPrice }}</span>
          </template>
        </el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="statusStr"
          label="采购状态"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payStatusStr"
          label="付款状态"
        ></el-table-column>
        <el-table-column
          show-overflow-tooltip
          prop="payTime"
          label="付款日期"
        ></el-table-column>
        <el-table-column min-width="200px" label="操作">
          <template slot-scope="{ row }">
            <el-button
              v-auth="'middle-purchase-detail:listSupplyPurchaseDetail'"
              @click="getDetail(row.purchaseNo)"
              type="text"
              >商品详情</el-button
            >
            <el-button
              v-if="row.payTypeEntityList && row.payTypeEntityList.length > 1"
              @click="amountDetailHandler(row.payTypeEntityList)"
              type="text"
              >付款详情</el-button
            >
            <template v-if="row.status == 1">
              <el-button
                v-if="row.isContainingOffline == 1"
                v-auth="'middle-purchase:mainSupplierConfirmPay'"
                @click="payHandler(row)"
                type="text"
              >
                下单
              </el-button>

              <el-button v-else @click="payHandler(row)" type="text">
                线下付款
              </el-button>
            </template>

            <template v-if="row.status == 3">
              <el-button
                v-auth="'middle-purchase:logistics'"
                @click="logInfo(row.purchaseNo)"
                type="text"
                >物流信息</el-button
              >
              <el-button
                v-auth="'middle-purchase:confirm'"
                @click="confirmTrigger(row.purchaseNo, row.status)"
                type="text"
              >
                确认收货</el-button
              >
            </template>

            <el-button
              v-if="row.status == 4"
              @click="confirmTrigger(row.purchaseNo, row.status)"
              type="text"
            >
              收货详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <pagination
      slot="pagination"
      :totalCount="totalCount"
      :page-size.sync="params.limit"
      :page.sync="params.page"
      @change="getData"
    />

    <LogInfoModal
      v-if="logInfoVisible"
      :visible.sync="logInfoVisible"
      :currentNo="currentNo"
    />
    <BuyDetailModal
      v-if="buyDetailVisible"
      :visible.sync="buyDetailVisible"
      :currentNo="currentNo"
    />

    <ConfirmModal
      @getData="getData(true)"
      v-if="confirmVisible"
      :visible.sync="confirmVisible"
      :currentNo="currentNo"
      :verificationStatus="verificationStatus"
    />

    <PayModal
      v-if="payVisible"
      @getData="getData(true)"
      :visible.sync="payVisible"
      :currentItem="currentItem"
    />

    <AmountModal
      v-if="amountVisible"
      :visible.sync="amountVisible"
      :payTypeEntityList="payTypeEntityList"
    />
  </page>
</template>

<script>
import watchParamsGetData from '@/mixins/watchParamsGetData'
import LogInfoModal from './components/LogInfoModal.vue'
import BuyDetailModal from './components/BuyDetailModal.vue'
import ConfirmModal from './components/ConfirmModal.vue'
import PayModal from './components/PayModal.vue'
import AmountModal from './components/AmountModal.vue'
export default {
  mixins: [watchParamsGetData],
  data() {
    return {
      currentNo: '',
      dataList: [],
      purchaseDetail: [],
      purchaseDetailData: [],
      currentSupplier: '',
      supplierList: [],
      totalCount: 0,
      params: {
        date: [],
        limit: 20,
        page: 1
      },
      statusObj: {
        1: '未下单',
        2: '待配送',
        3: '配送中',
        4: '已完结'
      },
      productDetailData: [],
      payVisible: false,
      confirmVisible: false,
      logInfoVisible: false,
      amountVisible: false,
      buyDetailVisible: false,
      loading: false,
      verificationStatus: '4',
      orderStatusList: [
        {
          label: '未下单',
          value: '1'
        },
        {
          label: '待配送',
          value: '2'
        },
        {
          label: '配送中',
          value: '3'
        },
        {
          label: '已完结',
          value: '4'
        }
      ],
      payStatusList: [
        {
          label: '已付款',
          value: '1'
        },
        {
          label: '未付款',
          value: '2'
        },
        {
          label: '部分付款',
          value: '3'
        }
      ],
      currentItem: {},
      payTypeEntityList: []
    }
  },
  components: {
    LogInfoModal,
    BuyDetailModal,
    ConfirmModal,
    PayModal,
    AmountModal
  },
  created() {
    this.getSuppliers()
    this.getData()
  },
  methods: {
    amountDetailHandler(payTypeEntityList) {
      this.payTypeEntityList = payTypeEntityList
      this.amountVisible = true
    },
    async getData(query) {
      try {
        this.loading = true

        let params = { ...this.params }

        if (query) {
          params.limit = 20
          params.page = 1
        }

        if (params.date && params.date.length) {
          params.beginDate = this.$utils.dateFormat(
            params.date[0],
            'YYYY-MM-DD'
          )
          params.endDate = this.$utils.dateFormat(params.date[1], 'YYYY-MM-DD')
        } else {
          params.beginDate = undefined
          params.endDate = undefined
        }

        const { date, ...sendData } = params

        const r = await this.$api.supplypurchase.getList(sendData)
        this.dataList = (r.page.list || []).map(item => {
          item.orderTime = this.$utils.dateFormat(item.orderTime, 'YYYY-MM-DD')
          item.payTime = this.$utils.dateFormat(item.payTime, 'YYYY-MM-DD')
          item.statusStr = this.statusObj[+item.status]
          item.payStatusStr = item.payStatus == 1 ? '已付款' : '未付款'
          return item
        })
        this.totalCount = r.page.totalCount
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    logInfo(purchaseNo) {
      this.logInfoVisible = true
      this.currentNo = purchaseNo
    },
    async getSuppliers() {
      const res = await this.$api.common.getSuppliers({
        limit: 1000,
        page: 1
      })
      this.supplierList = (res.page.list || []).map(item => {
        return {
          label: item.name,
          value: item.id
        }
      })
    },
    reload(event) {
      this.currentPage = 1
      this.getData()
    },

    getDetail(purchaseNo) {
      this.currentNo = purchaseNo
      this.buyDetailVisible = true
    },

    payHandler(obj) {
      this.currentItem = obj
      this.payVisible = true
    },

    confirmTrigger(purchaseNo, verificationStatus) {
      this.confirmVisible = true
      this.currentNo = purchaseNo
      this.verificationStatus = verificationStatus
    }
  }
}
</script>

<style scoped lang="scss"></style>
