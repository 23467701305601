<template>
  <el-dialog
    :destroy-on-close="true"
    :close-on-click-modal="false"
    title="收货地址"
    :visible.sync="show"
    width="400px"
  >
    <div class="content" v-loading="loading">
      <el-form
        ref="payForm"
        :model="payInfo"
        size="small"
        label-width="120px"
        :rules="rules"
      >
        <el-form-item label="收货人：" prop="cargoReceiver">
          <el-input
            v-model="payInfo.cargoReceiver"
            placeholder="请输入收货人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话：" prop="phone">
          <el-input
            v-model="payInfo.phone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="storeAddress">
          <AreaSelect :value.sync="payInfo.storeAddress" />
        </el-form-item>

        <el-form-item label="详细地址" prop="address">
          <el-input
            v-model="payInfo.address"
            placeholder="请输入详细地址"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="currentItem && currentItem.isContainingOffline == 0"
          label="线下付款日期："
          prop="payTime"
        >
          <el-date-picker
            :pickerOptions="pickerOptions"
            style="width: 100%"
            v-model="payInfo.payTime"
            type="date"
            placeholder="选择付款日期"
          >
          </el-date-picker>
        </el-form-item>
      </el-form>
    </div>

    <span slot="footer" class="dialog-footer">
      <el-button @click="show = false">关闭</el-button>
      <el-button :loading="btnLoading" type="primary" @click="confirmOrder"
        >下单</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import dialogCommonParams from '@/mixins/dialogCommonParams'

export default {
  mixins: [dialogCommonParams],
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      rules: {
        cargoReceiver: {
          required: true,
          message: '请输入收货人',
          trigger: 'blur'
        },
        phone: {
          required: true,
          message: '请输入联系电话',
          trigger: 'blur'
        },
        storeAddress: {
          required: true,
          message: '请选择收货地址',
          trigger: 'blur'
        },
        address: {
          required: true,
          message: '请输入详细地址',
          trigger: 'blur'
        },
        payTime: {
          required: true,
          message: '请选择付款日期',
          trigger: 'blur'
        }
      },
      loading: false,
      btnLoading: false,
      payInfo: {
        cargoReceiver: '',
        phone: '',
        storeAddress: [],
        address: '',
        payTime: ''
      }
    }
  },
  created() {
    this.getMerchantAddress()
  },
  methods: {
    confirmOrder() {
      this.$refs.payForm.validate(async valid => {
        if (valid) {
          try {
            const { storeAddress, ...sendData } = this.payInfo

            if (storeAddress && storeAddress.length) {
              sendData.provinceId = storeAddress[0]
              sendData.cityId = storeAddress[1] || ''
              sendData.regionId = storeAddress[2] || ''
            }

            sendData.id = this.currentItem.id
            sendData.isContainingOffline = this.currentItem.isContainingOffline
            sendData.isOnlyContainingOffline =
              this.currentItem.isOnlyContainingOffline

            this.btnLoading = true

            await this.$api.supplypurchase.mainSupplierConfirmPay(sendData)

            this.$message.success('下单成功！')

            this.show = false
            this.$emit('getData')
          } catch (e) {
            console.log(e)
          } finally {
            this.btnLoading = false
          }
        }
      })
    },

    async getMerchantAddress() {
      try {
        this.loading = true
        const r = await this.$api.supplypurchase.getMerchantAddress()
        this.payInfo.cargoReceiver = (r.entity && r.entity.cargoReceiver) || ''
        this.payInfo.phone = (r.entity && r.entity.phone) || ''
        this.payInfo.storeAddress = (r.entity && r.entity.storeAddress) || ''
        this.payInfo.payTime = this.$utils.dateFormat(Date.now())
        this.payInfo.address = r.entity.address || ''
        if (r.entity.provinceId) {
          this.payInfo.storeAddress = [
            r.entity.provinceId,
            r.entity.cityId,
            r.entity.regionId
          ]
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped lang="scss"></style>
